import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL, API_KEY } from '../config/config';

export default function PasswordReset() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [step, setStep] = useState('request'); // 'request' or 'reset'

  // Extract the token from the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('t');

  // If there's a token, verify it and show the reset form
  console.debug('password reset token:', token);
  useEffect(() => {
    if (!token) return;

    const verifyToken = async () => {
      try {
        console.debug(
          `verifying password reset token at URL: ${BASE_URL}/password/check-reset-token`
        );
        const response = await fetch(`${BASE_URL}/password/check-reset-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify({ token }),
        });

        console.debug('password reset token response:', response);
        if (!response.ok) throw new Error('Invalid token');

        const data = await response.json();
        setEmail(data.email);
        setStep('reset');
      } catch (err) {
        setError('Invalid or expired reset link. Please try again.');
        setStep('request');
      }
    };

    verifyToken();
  }, [token]);

  // Handle password reset request
  const handleRequestReset = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/password/request-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess(
          'If an account exists, you will receive a reset email shortly.'
        );
        setError('');
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to request password reset');
      }
    } catch (err) {
      setError('Network error occurred');
    }
  };

  // Handle password reset
  const handleReset = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/password/set`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          token,
          password,
          confirmPassword,
        }),
      });

      if (response.ok) {
        setSuccess('Password reset successful! Redirecting to login...');
        setError('');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to reset password');
      }
    } catch (err) {
      setError('Network error occurred');
    }
  };

  return (
    <div className="account-validator-container">
      <h2 className="account-validator-heading">
        {step === 'request' ? 'Reset Your Password' : 'Set New Password'}
      </h2>

      {error && <div className="account-validator-error">{error}</div>}
      {success && <div className="account-validator-success">{success}</div>}

      {!success && (
        <div className="account-validator-form">
          {step === 'request' ? (
            <>
              <div className="account-validator-field">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="account-validator-input"
                  required
                />
              </div>
              <button
                onClick={handleRequestReset}
                className="account-validator-button"
                disabled={!email}
              >
                Request Password Reset
              </button>
            </>
          ) : (
            <>
              <div className="account-validator-field">
                <label>New Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                  className="account-validator-input"
                  required
                />
              </div>
              <div className="account-validator-field">
                <label>Confirm Password</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                  className="account-validator-input"
                  required
                />
              </div>
              <button
                onClick={handleReset}
                className="account-validator-button"
                disabled={!password || !confirmPassword}
              >
                Reset Password
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
