import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { TextField, Button, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import '../styles/theme.ts';
import '../styles/BaseTheme.css';
import '../styles/PaymentForm.css';

export interface PaymentFormProps {
  subscriptionTypeId: string;
  renewalInterval: string;
}

interface SignupFormData {
  firstName: string;
  lastName: string;
  login: string;
  email: string;
  password: string;
  seats: number;
}

const elementsOptions = {
  style: {
    base: {
      color: '#f8f8f2',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#a8a8a8',
      },
      ':focus': {
        color: '#ffffff',
        backgroundColor: 'rgba(102, 0, 255, 0.5)',
      },
    },
    invalid: {
      color: '#ff6b6b',
    },
  },
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
  subscriptionTypeId,
  renewalInterval,
}) => {
  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    firstName: '',
    lastName: '',
    login: '',
    password: '',
    seats: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<number | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const validateLogin = async (login: string) => {
    if (login.length < 3) {
      setLoginError('Login must be at least 3 characters');
      setSuggestions([]);
      return;
    }
    try {
      const response = await fetch(
        `${BASE_URL}/validate/next-login?base=${encodeURIComponent(login)}`
      );
      const result = await response.json();
      console.debug(`Validating login ${login}: `, result);
      if (response.ok) {
        setLoginError(null);
        setSuggestions(result.suggestions || []);
      } else {
        setLoginError(result.error || 'Unable to validate login');
        setSuggestions([]);
      }
    } catch (e) {
      setLoginError('An error occurred while validating login');
      setSuggestions([]);
    }
  };

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLogin = e.target.value;
    setFormData({ ...formData, login: newLogin });

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      window.setTimeout(() => {
        validateLogin(newLogin);
      }, 500)
    );
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!validateEmail(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }

    // Validate login is unique

    setLoading(true);
    setError(null);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError(
        'Payment information is incomplete. Please check your card details.'
      );
      return;
    }

    try {
      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
      const creationEvent = {
        type: 'card',
        card: cardElement,
        paymentMethod: paymentMethod?.id || 'none',
        timestamp: new Date().toISOString(),
      };
      console.debug('Created payment method: creationEvent', creationEvent);

      if (stripeError) {
        setError(stripeError.message || 'Payment method creation failed');
        console.error('Stripe error:', stripeError);
        return;
      }

      const response = await fetch(`${BASE_URL}/sign-up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          subscriptionPlanId: subscriptionTypeId,
          renewalInterval,
          paymentMethod: paymentMethod?.id,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // Redirect to a "check your email" page
        navigate('/complete-signup', {
          state: {
            email: formData.email,
          },
        });
      } else {
        setError(result.error || 'Failed to create account');
      }
    } catch (e) {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <div className="account-section">
        <h2 className="section-title">Account Details</h2>
        <TextField
          className="form-field"
          label="First Name"
          value={formData.firstName}
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
          required
        />
        <TextField
          className="form-field"
          label="Last Name"
          value={formData.lastName}
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          required
        />
        <TextField
          className="form-field"
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
          type="email"
          error={!!error && error.includes('email')}
        />
        <TextField
          className="form-field"
          label="Login"
          value={formData.login}
          onChange={handleLoginChange}
          required
          helperText={
            loginError ||
            (suggestions.length > 0
              ? `Suggestions: ${suggestions.join(', ')}`
              : 'This will be your login name. Type to check availability')
          }
          error={!!loginError}
          // onChange={(e) => setFormData({ ...formData, login: e.target.value })}
          // required
          // helperText="This will be your login name"
        />
        {/* <TextField
          className="form-field"
          label="Passsword"
          type="password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          // required
          helperText="Minimum 8 characters, at least one letter and one number"
        /> */}
        <TextField
          className="form-field seats-field"
          label="Number of Seats"
          type="number"
          value={formData.seats}
          onChange={(e) =>
            setFormData({ ...formData, seats: parseInt(e.target.value) })
          }
          required
          InputProps={{
            inputProps: { min: 1 },
            startAdornment: (
              <InputAdornment position="start">👥</InputAdornment>
            ),
          }}
        />{' '}
      </div>

      <div className="payment-section">
        <h2 className="section-title">Payment Details</h2>
        <div className="card-element-wrapper">
          <CardElement options={elementsOptions} />
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      <Button
        className="submit-button"
        variant="contained"
        type="submit"
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Complete Signup'}
      </Button>

      <p className="email-notice">
        You'll receive an email to complete your account setup.
      </p>
    </form>
  );
};
