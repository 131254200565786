import { FC } from 'react';
// import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { PaymentForm } from '../components/PaymentForm';
import '../styles/PaymentForm.css';

// interface SubscribeProps {
//   subscriptionTypeId: string;
//   renewalInterval: string;
// }

const Subscribe: FC = () => {
  const [searchParams] = useSearchParams();
  const subscriptionTypeId = searchParams.get('subscriptionTypeId') || '';
  const renewalInterval = searchParams.get('renewalInterval') || '';

  return (
    <div className="payment-container">
      <div className="payment-area">
        <h1>Subscribe</h1>
        <div className="payment-form">
          <PaymentForm
            subscriptionTypeId={subscriptionTypeId}
            renewalInterval={renewalInterval}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
