// src/components/PlanSelection.tsx
import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config/config';
import { PaymentForm } from './PaymentForm';
import { toTitleCase } from '../utils/textUtils';
import '../styles/SubscriptionPlans.css';

// import { useSession } from '../contexts/SessionContext';

interface SubscriptionPlan {
  id: string;
  name: string;
  renewalIntervals: {
    interval: string;
    cost: number;
  }[];
  features: string[];
}

interface PlanIdentifier {
  planId: string;
  name: string;
  interval: string;
}

export const SubscriptionPlans: React.FC = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanIdentifier | null>(null);
  const navigate = useNavigate();
  // const { apiKey } = useSession(); // This probably needs to be an unauthenticated page.

  const apiKey = process.env.REACT_APP_API_KEY;
  // Fetch plans from API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        console.debug(
          `Fetching subscription plans from ${BASE_URL}/subscription-plan?visibility=web`
        );
        const response = await fetch(
          `${BASE_URL}/subscription-plan?visibility=web`,
          {
            method: 'GET',
            headers: {
              'X-API-Key': apiKey!,
              'Content-Type': 'application/json',
            },
          }
        );
        const data = await response.json();
        console.debug(data);

        if (response.ok) {
          setPlans(data);
        } else {
          setError(`Failed to fetch subscription plans. API key: ${apiKey}`);
        }
      } catch (error) {
        setError('An error occurred while fetching subscription plans');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = (planId: string, name: string, interval: string) => {
    const plan = { planId, name, interval };
    setSelectedPlan(plan);
  };

  const handleNext = () => {
    if (selectedPlan) {
      navigate(
        `/payment/?planId=${selectedPlan.planId}&interval=${selectedPlan.interval}`
      );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="plans">
      <h2 className="plans-heading">Select a Subscription Plan</h2>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <div className="plan-group">
        {plans.map((plan) => (
          <div className="plan" key={plan.id}>
            <h3>{plan.name}</h3>
            <div className="plan-features">
              <ul className="plan-feature">
                {plan.features.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>
            <div className="intervals">
              {plan.renewalIntervals.map((interval) => (
                <div key={interval.interval}>
                  <div className="interval-cost">
                    {toTitleCase(interval.interval)}: ${interval.cost}
                  </div>
                  <div className="button-container">
                    <Button
                      className="button"
                      variant="outlined"
                      onClick={() =>
                        handleSelectPlan(plan.id, plan.name, interval.interval)
                      }
                    >
                      Select {toTitleCase(plan.name)} with{' '}
                      {toTitleCase(interval.interval)} payments
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {selectedPlan && (
        <div className="payment-container">
          <div className="payment-area">
            <div className="payment-form">
              <PaymentForm
                subscriptionTypeId={selectedPlan.planId}
                renewalInterval={selectedPlan.interval}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlans;
